import { useSelector } from "react-redux"
import { selectIsDesktop } from "../../../../../../../../store/settingsSlice/selectors"
import { SlidePage } from "./SlidePage";
import { SlidePageContainerProps } from "./types";

const SlidePageContainer: React.FC<SlidePageContainerProps> = ({tests}) => {
    const isDesktop = useSelector(selectIsDesktop);

    return (
        <SlidePage isDesktop={isDesktop} tests={tests}/>
    );
}

export default SlidePageContainer;
