import React, { useEffect } from 'react';

import {
  Panel,
  PanelHeader,
  PanelHeaderBack,
  Group,
  Spacing,
} from '@vkontakte/vkui';

import { PanelType } from '../../../../constants/Panel';
import { QuestionType } from '../../../../types/question.type';

import { FullDescription } from '../../../../components/test/FullDescription';
import { TestLabel } from '../../../../components/test/TestLabel';
import { ProgressBar } from '../../../../components/test/ProgressBar';
import { Question } from '../../../../components/test/Question';
import { Answer } from '../../../../components/test/Answer';
import { TestNavigation } from '../../../../components/test/TestNavigation';

export type CurrentTestPanelPropsType = {
  id: PanelType;
  question?: QuestionType;
  questionNumber?: number;
  setInstruction: () => void;
  onBackClick: () => void;
  hasProgressBar: boolean;
  isDesktop: boolean;
  hasInitialInstruction: boolean;
  answer: (items: object[]) => void;
  moveForward: () => Promise<void>;
  moveBack: () => void;
  hasRequiredTokenScope: boolean;
  setAccessToken: () => Promise<void>;
  showFullDescription: boolean;
};

export const CurrentTestPanel: React.FC<CurrentTestPanelPropsType> = ({
  id,
  question,
  questionNumber = 1,
  setInstruction,
  onBackClick,
  hasProgressBar,
  isDesktop,
  hasInitialInstruction,
  answer,
  moveForward,
  moveBack,
  hasRequiredTokenScope,
  setAccessToken,
  showFullDescription,
}) => {
  
  useEffect(() => {
    if (!hasRequiredTokenScope) {
      setAccessToken();
      return;
    }

    setAccessToken();
    
    if (hasInitialInstruction && !showFullDescription) {
      setInstruction();
    }
  }, [showFullDescription]);
  
  if (!question) {
    return null;
  }

  const hasNavigation = question.type !== 'button' && question.type !== 'belbinTest';
  const isFirstQuestion = questionNumber === 1;

  return (
    <Panel id={id}>
      <PanelHeader before={<PanelHeaderBack onClick={onBackClick} />}>
        Тест
      </PanelHeader>
      {showFullDescription ? (
        <FullDescription />
      ) : (
        <>
          <Group>
            <TestLabel/>
            {hasProgressBar && <ProgressBar />}
            <Question
              question={question}
              questionNumber={questionNumber}
            />
            <Answer
              handler={question.type}
              possibleAnswers={question.answers}
              givenAnswers={question.user_answers}
              metaInfo={question.meta_info}
              answer={answer}
              moveForward={moveForward}
              moveBack={moveBack}
            />
          </Group>
          {!isDesktop && <Spacing size={16} />}
          {hasNavigation && (
            <TestNavigation
              isFirstQuestion={isFirstQuestion}
              moveForward={moveForward}
              moveBack={moveBack}
            />
          )}
        </>
      )}
    </Panel>
  );
};