import { SlidePage } from './components/SlidePage';
import { PageOption } from '../../../../../../components/common/HorizontalSlides/types';
import { TestRecommendation } from './TestRecommendation';
import { selectResultData } from '../../../../../../store/resultSlice/resultSliceSelectors';
import { selectIsDesktop } from '../../../../../../store/settingsSlice/selectors';
import { useEffect, useState } from 'react';
import { Spinner } from '@vkontakte/vkui';
import { useSelector } from 'react-redux';
import { MODE_OPTIONS } from '../../../../../../components/common/HorizontalSlides/constants';
import usersApi from '../../../../../../api/UsersApi';

const TestRecommendationContainer: React.FC = () => {
  const result = useSelector(selectResultData);
  const isDesktop = useSelector(selectIsDesktop);
  const [tests, setTests] = useState<undefined | any[]>(undefined);

  useEffect(() => {
    const getTests = async () => {
      const data = await usersApi.getTestsRecommendationByTestShortName(result!.test_short_name, 4);
      setTests(data);
    }
    getTests();
  },[]);

  const getPagesOptions = (): PageOption[] => {
    if (tests) {
      const pagesOptions = [];
      if (isDesktop) {
        for (let i = 0; i < tests?.length; i += 2){
          pagesOptions.push({
            page: <SlidePage tests={[tests[i], tests[i + 1]]}/>,
            button: {
              text: 'Другие тесты',
              mode: MODE_OPTIONS.PRIMARY as 'primary',
              onClick: undefined,
            },
          });
        }
     }
     else {
      for (let i = 0; i < tests?.length; i += 1){
        pagesOptions.push({
          page:<SlidePage tests={[tests[i]]}/>,
          button: {
            text: 'Другие тесты',
            mode: undefined,
            onClick: undefined,
          }
        });
      }
    }

    return pagesOptions;
    }

    return [];
  };

  return (
    tests ? 
      <TestRecommendation pagesOptions={getPagesOptions()}/>
    :
      <Spinner/>
  );
};

export default TestRecommendationContainer;
