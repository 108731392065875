import { PrivacyPolicy } from "./PrivacyPolicy";
import { PRIVACY_POLICY_URL } from "../../../constants/PrivacyPolicy";

const PrivacyPolicyContainer = () => {
    const onRedirectionToPrivacyPolicy = () => {
        window.open(PRIVACY_POLICY_URL);
    }

    return <PrivacyPolicy onRedirectionToPrivacyPolicy={onRedirectionToPrivacyPolicy}/>
};

export default PrivacyPolicyContainer;
