import React from 'react';

import {
  Group,
  Panel,
  PanelHeader,
  Spacing,
} from '@vkontakte/vkui';

import { PanelType } from '../../../../constants/Panel';
import { NAVIGATION } from '../../../../constants/Navigation';

import { DesktopNavigation } from '../../../../components/navigation/DesktopNavigation';

import { AppShare } from '../../../../components/settings/AppShare';
import { DecorationSettings } from '../../../../components/settings/DecorationSettings';
import { ContactInfo } from '../../../../components/settings/ContactInfo';
import { PrivacyPolicy } from '../../../../components/settings/PrivacyPolicy';

export type SettingsPanelPropsType = {
  id: PanelType;
  isDesktop: boolean;
};

export const SettingsPanel: React.FC<SettingsPanelPropsType> = ({ id, isDesktop }) => {
  return (
    <Panel id={id}>
      <PanelHeader>Настройки</PanelHeader>
      <Group>
        {isDesktop && <DesktopNavigation id={NAVIGATION.DESKTOP} />}
        <AppShare />
        <DecorationSettings />
        <ContactInfo />
        <PrivacyPolicy/>
      </Group>
    </Panel>
  );
};
