import { useState, useEffect, useCallback, useLayoutEffect } from 'react';

import { useSelector } from 'react-redux';
import { useAppDispatch } from './useAppDispatch';

import store from '../store';

import { updateResultFromArray } from '../store/resultsSlice';
import { updateResult } from '../store/resultSlice';

import {
  updateUserAnswers,
  updateAnsweredQuestionsCount,
  acceptQuestion,
  skipQuestion,
  resetQuestions,
  resetPassingStatus,
  resetPercents,
} from '../store/testSlice';
import {
  selectTest,
  selectTestShortName,
  selectTestCanRedo,
  selectAcceptedOrSkippedQuestionsCount,
  selectFirstQuestionIndex,
  selectTestQuestionsCount,
  areDisplayConditionsFulfilled,
} from '../store/testSlice/testSelectors';
import { updateAnsweredQuestionsCountByTestShortName } from '../store/testsSlice';

import { resetPopoutType, setPopout, setPopoutType } from '../store/popoutSlice';
import { POPOUT } from '../constants/Popout';

import { validateAnswers } from '../utils/validateAnswers';
import { cacheImages } from '../utils/cacheImages';

import passingsApi from '../api/PassingsApi';
import resultsApi from '../api/ResultsApi';

import { UseRouterType } from './useRouter';

import { QuestionType } from '../types/question.type';
import { ResultType } from '../types/result.type';

import { VIEW } from '../constants/View';
import { PANEL } from '../constants/Panel';
import usersApi from '../api/UsersApi';

export type UseTestType = {
  question: QuestionType
  answer: (items: object[]) => void;
  moveForward: () => Promise<void>;
  moveBack: () => void;
};

export const useTest = (router: UseRouterType): UseTestType => {
  const dispatch = useAppDispatch();

  const test = useSelector(selectTest);
  const testShortName = useSelector(selectTestShortName);
  const canRedo = useSelector(selectTestCanRedo);
  const questionsCount = useSelector(selectTestQuestionsCount);
  const initialIndex = useSelector(selectFirstQuestionIndex);

  const [index, setIndex] = useState(initialIndex || 0);

  if (!test?.questions) {
    throw Error('Test questions are undefined');
  }

  useLayoutEffect(() => {
    // Кэшируем все картинки из вопросов теста
    let urlList: string[] = [];
    const questionsWithPhotos = test.questions?.filter((question) => question.photos) || [];

    questionsWithPhotos.forEach((question) => {
      if (Array.isArray(question.photos)) {
        urlList = [...urlList, ...(question.photos.map((photo) => photo.url))];
      }
    });

    cacheImages(urlList);
  }, []);

  const resetTest = useCallback(() => {
    dispatch(resetPercents());
    dispatch(resetQuestions());
    dispatch(resetPassingStatus());
  }, [dispatch]);

  const finishTest = useCallback(async () => {
    if (!testShortName) {
      return;
    }

    // Выводим лоадер (начало отправки)
    dispatch(setPopoutType(POPOUT.SMART_SPINNER));

    // Получаем результаты тестирования
    const result = await resultsApi.formResult(testShortName) as ResultType;

    // Обновляем стейты results и result
    dispatch(updateResultFromArray(result));
    dispatch(updateResult(result));

    // Сбрасываем акутальность рекомендаций
    usersApi.expireRecommendations();

    // Убираем лоадер (конец отправки)
    dispatch(resetPopoutType());

    // Обновляем историю для корректного перехода
    // на панель с результатами из соседнего view
    window.history.replaceState({ ...window.history.state, panel: PANEL.TESTS }, '');
    await router.forward({ view: VIEW.PROFILE, panel: PANEL.PROFILE, modal: null }, true);

    await router.forward({ view: VIEW.PROFILE, panel: PANEL.RESULT });

    // Сбрасываем текущее прохождение
    resetTest();

  }, [dispatch, router, testShortName, resetTest]);

  const answer = useCallback(
    (items: object[]) => {
      if (!test.questions) {
        return;
      }

      dispatch(updateUserAnswers({
        q_id: test.questions[index].q_id,
        time: Date.now(),
        state: 'modified',
        items,
      }));
    },
    [dispatch, index, test.questions]
  );

  /**
   * Проверка на то, является ли текущий вопрос последним в тесте
   */
  const isLastQuestion = useCallback(
    (index: number) => index + 1 === test.questions?.length,
    [test]
  );

  /**
   * Обновляем процент отвеченных вопросов
   */
  const updatePercents = useCallback(() => {
    if (!testShortName) {
      return;
    }

    const answeredQuestionsCount = selectAcceptedOrSkippedQuestionsCount(store.getState()) || 0;

    dispatch(updateAnsweredQuestionsCount(answeredQuestionsCount));
    dispatch(updateAnsweredQuestionsCountByTestShortName({
      testShortName,
      count: answeredQuestionsCount,
    }));
  }, [testShortName, dispatch]);

  /**
   * Переход к следующему вопросу теста
   */
  const goToNextQuestion = useCallback(() => {
    const state = store.getState();
    const test = selectTest(state);

    if (!test || !test.questions) {
      return;
    }

    if (isLastQuestion(index)) {
      return;
    }

    // Пропуск вопросов (skipped), показываемых по условию, пока не доступен в тестах,
    // в которых разрешен возврат к предыдущим вопросам
    if (canRedo) {
      setIndex((index) => index + 1);
      return;
    }

    let nextIndex = index + 1;
    const skippedQuestionsIds = [];

    while ((nextIndex < test.questions.length) &&
      (
        (test.questions[nextIndex].state !== 'unanswered') ||
        !areDisplayConditionsFulfilled(state, nextIndex)
      )
    ) {
      const question = test.questions[nextIndex];

      // Скипаем вопрос (фронтенд)
      if (question.state === 'unanswered' && !areDisplayConditionsFulfilled(state, nextIndex)) {
        dispatch(skipQuestion(question.q_id));
        skippedQuestionsIds.push(question.q_id);
      }

      nextIndex++;
    }

    // Скипаем вопросы (бэкенд)
    if (skippedQuestionsIds.length && testShortName) {
      passingsApi.skipQuestions({
        questionsIds: skippedQuestionsIds,
        testShortName,
      });
    }

    // Если все следующие вопросы - skipped, заканчиваем тест
    if (nextIndex >= test.questions.length) {
      finishTest();
      return;
    }

    setIndex(nextIndex);
  }, [canRedo, testShortName, index, isLastQuestion, dispatch, finishTest]);

  /**
   * Движение вперёд по тесту
   */
  const moveForward = useCallback(async () => {
    const test = selectTest(store.getState());

    if (!test || !test.questions || !testShortName) {
      return;
    }

    try {
      const {
        q_id,
        user_answers,
        validation_rules,
        state,
      } = test.questions[index];

      // Пропускаем вопрос, если он уже был отвечен
      if (state === 'accepted') {
        goToNextQuestion();
        return;
      }

      // Клиентская валидация ответов
      validateAnswers(user_answers, validation_rules);

      // Выводим лоадер (начало отправки)
      dispatch(setPopoutType(POPOUT.SMART_SPINNER));

      // Отправка ответов на сервер
      await passingsApi.saveAnswer({
        test_short_name: testShortName,
        q_id,
        items: user_answers,
      });

      // Помечаем вопрос как отвеченный
      dispatch(acceptQuestion(q_id));

      // Убираем лоадер (конец отправки)
      dispatch(resetPopoutType());

      const answeredQuestionsCount = selectAcceptedOrSkippedQuestionsCount(store.getState()) || 0;

      // Заканчиваем тест, если ответили на все вопросы
      if (answeredQuestionsCount === questionsCount) {
        updatePercents();
        finishTest();
        return;
      }

      goToNextQuestion();
      updatePercents();
    } catch (error) {
      // Выводим снэкбар с сообщением ошибки
      if (error instanceof Error) {
        dispatch(setPopout({
          type: POPOUT.ERROR_SNACKBAR,
          meta_info: {
            message: error.message,
            duration: 2000,
          },
        }));
      }
    }
  }, [dispatch, index, testShortName, goToNextQuestion, finishTest, questionsCount, updatePercents]);

  /**
   * Движение назад по тесту
   */
  const moveBack = useCallback(() => {
    setIndex((index) => index > 0 ? (index - 1) : index);
    updatePercents();
  }, [updatePercents]);

  useEffect(() => {
    // Скроллим к началу ответа
    window.scrollTo({ top: 0 });
  }, [index]);

  return {
    question: test.questions[index],
    answer,
    moveForward,
    moveBack,
  };
};
