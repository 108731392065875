import api from './api';

import { SettingsType } from '../types/settings.type';
import { serializeToQueryParams } from '../utils/serializeToQueryParams';

export type GetTestsQueryParams = {
  testsShortName?: string[];
  withQuestions?: boolean;
  withPercent?: boolean;
};

function getUser() {
  return api('users');
}

function saveSettings(settings: Partial<SettingsType>) {
  return api('users/save_settings', settings, { method: 'PUT' });
}

function acceptPrivacyPolicy() {
  return api('users/accept_privacy_policy', {}, { method: 'PUT' });
}

function getTestsRecommendationByTestShortName(test_short_name: string, count: number) {
  const queryParams = serializeToQueryParams({
      test_short_name: test_short_name,
      count: count,
  });

  return api(`users/recommendation_by_test_short_name/${queryParams && ('?' + queryParams)}`);
}

function expireRecommendations() {
  return api('users/expire_recommendations');
}

const usersApi = { getUser, saveSettings, acceptPrivacyPolicy, expireRecommendations, getTestsRecommendationByTestShortName };

export default usersApi;
