import React, { useMemo, useState } from "react";
import { HorizontalSlides } from "./HorizontalSlides";
import { HorizontalSlidesContainerProps } from "./types";

const HorizontalSlidesContainer = React.forwardRef(({pagesOptions}: HorizontalSlidesContainerProps, ref) => {
  const [page, setPage] = useState<number>(0);
  const pagesAmount = useMemo(() => pagesOptions.length, [pagesOptions]);

  const swapPage = (direction: number) => {
    setPage((page) => (page + direction) % pagesAmount);
  };

  const onButtonClick = () => {
    if (pagesOptions[page].button.onClick != undefined){
      pagesOptions[page].button.onClick?.();
      return;
    }

    swapPage(1);
  };

  return (
    <HorizontalSlides
      pagesOptions={pagesOptions}
      page={page}
      swapPage={swapPage}
      ref={ref}
      onButtonClick={onButtonClick}
    />
  );
});

export default HorizontalSlidesContainer;
