import { Icon28DocumentTextOutline } from "@vkontakte/icons";
import { Div, SimpleCell } from "@vkontakte/vkui";
import { GroupHeader } from "../../common/GroupHeader";
import { FC } from "react";
import { PrivacyPolicyProps } from "./types";

import styles from './PrivacyPolicy.module.css';

export const PrivacyPolicy: FC<PrivacyPolicyProps> = ({ onRedirectionToPrivacyPolicy }) => {
    return (
        <>
            <Div className={styles.header}>
                <GroupHeader>
                    Другое
                </GroupHeader>
            </Div>
            <SimpleCell
                before={<Icon28DocumentTextOutline/>}
                onClick={onRedirectionToPrivacyPolicy}
            >   
                Политика конфиденциальности
            </SimpleCell>         
        </>
    );
};
