import { Title } from '@vkontakte/vkui';
import Measure, { MeasuredComponentProps } from 'react-measure';

import { HorizontalSlides }  from './../../../../../../components/common/HorizontalSlides';
import styles from './TestReacommendation.module.css';
import { TestRecommendationProps } from './types';
import {useState } from 'react';

export const TestRecommendation: React.FC<TestRecommendationProps> = ({ pagesOptions }) => {
  const [height, setHeight] = useState(0);

  if (pagesOptions.length == 0){
    return <></>;
  }
    
  return (
    <div>
      <div className={styles.titleContainer}>   
        <Title>Возможно, вам также будет интересно</Title>
      </div>
      <div style={{ height: height}}>
        <Measure
          bounds
          onResize={(contentReact) => {
            if(contentReact.bounds){
              setHeight(contentReact.bounds.height);
            }
          }}
        > 
          {(props: MeasuredComponentProps) => {
            return <HorizontalSlides pagesOptions={pagesOptions} ref={props.measureRef}/>
          }}
        </Measure>
      </div>
    </div>
    );
};
