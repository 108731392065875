import { Button } from '@vkontakte/vkui';
import { AnimatePresence, motion } from 'framer-motion';

import { HorizontalSlidesProps } from './types';
import  styles  from './HorizontalSlides.module.css';
import { VARIANTS } from './constants';
import React from 'react';

export const HorizontalSlides = React.forwardRef(({ pagesOptions, swapPage, page, onButtonClick }: HorizontalSlidesProps, ref) => {
  return (
      <div className={styles.container}>    
        <AnimatePresence initial={false}>
          <motion.div
            //@ts-ignore
            ref={ref}
            className={styles.slide}
            key={page}
            variants={VARIANTS}
            initial='enter'
            animate='center'
            exit='exit'
          >
            <div>
              {pagesOptions[page].page}
            </div>
            {pagesOptions.length > 1 && 
              <div className={styles.button}>
                <Button
                  onClick={onButtonClick}
                  mode={pagesOptions[page].button.mode}
                  size='l'
                  stretched
                >
                  {pagesOptions[page].button.text}
                </Button>
              </div>
            }
          </motion.div>
        </AnimatePresence> 
      </div>    
  );
});
