import { CardGrid } from '@vkontakte/vkui';

import styles from './index.module.css';
import { SlidePageProps } from './types';
import { TestCard } from '../../../../../../../../components/test/TestCard';

export const SlidePage: React.FC<SlidePageProps> = ({ tests, isDesktop }) => {
  return (
    <CardGrid size={isDesktop? 'm': 'l'}>
      <div className={styles.cardGrid}>
        {tests.map((test) => {              
          return test && (
            <TestCard 
              test={test}
              key={`${test.short_name}_test`}
              isRecommended={true}
            />
          )                     
        })}
      </div>
    </CardGrid>
  );
};
